<template>
  <div class="container-fluid">
    <div class="row justify-content-center">
      <div class="col-12 mt-3">
        <addHeader
          :title="$t('subscriptionsOperations.form.CreateASubscription')"
          :description="$t('subscriptionsOperations.form.FromHereYouCanCreateANewSubscriptionProcess')"
          v-if="$route.name == 'subscriptionsOperationsCreate'"
        />
        <addHeader
          :title="$t('subscriptionsOperations.form.ModifyTheSubscriptionProcess')"
          :description="$t('subscriptionsOperations.form.FromHereYouCanModifyTheSubscriptionProcess')"
          v-if="$route.name == 'subscriptionsOperationsEdit'"
        />
        <div class="mb-4 row">
          <dvider
            :title="$t('subscriptionsOperations.form.basicInformation')"
            :description="$t('subscriptionsOperations.form.BasicInformationForTheOperation')"
            noLine="true"
          />
          <connectionInput
            :title="$t('subscriptionsOperations.form.BillNumbering')"
            :group="$t('subscriptionsOperations.form.Groups')"
            v-model="item.invoice_group"
            :disabled="
              $user.admin || $user.role.subscriptions_operations_edit_product
                ? false
                : true
            "
            :values="$database.invoiceGroups"
            v-if="$route.name == 'subscriptionsOperationsCreate'"
            :hasErorr="errors.invoice_group"
            :error="$t('subscriptionsOperations.form.ThisFieldIsRequired')"
          />
          <formInput
            :title="$t('subscriptionsOperations.form.InvoiceCode')"
            v-model="item.code"
            :disabled="true"
            v-if="$route.name == 'subscriptionsOperationsEdit'"
            :hasErorr="errors.code"
            maxlength="255"
          />

          <searchGroupInput
            :type="'customer'"
            :values="$database.customers.filter((el) => el.stat == 1)"
            :inputs="$option.invoices_customer_data"
          />

          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{$t('subscriptionsOperations.form.date')}}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('subscriptionsOperations.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('subscriptionsOperations.form.Now')"
                  v-model="item.date"
                  :disabled="
                    $user.admin ||
                    $user.role.subscriptions_operations_edit_product
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>
          <div class="form-group row" :class="'col-md-6'">
            <label :class="'col-md-4'">{{
              $t('subscriptionsOperations.form.ExpiryDate')
            }}</label>
            <div :class="'col-md-7'">
              <div class="icon-input w-100 to-left">
                <span><i class="far fa-calendar"></i></span>

                <VueCtkDateTimePicker
                  :label="$t('subscriptionsOperations.form.ChooseTimeAndDate')"
                  :button-now-translation="$t('subscriptionsOperations.form.Now')"
                  v-model="item.expiry_date"
                  :disabled="
                    $user.admin ||
                    $user.role.subscriptions_operations_edit_product
                      ? false
                      : true
                  "
                  format="YYYY-MM-DD hh:mm a"
                  color="#631263"
                  button-color="#631263"
                />
              </div>
            </div>
          </div>
          <selectInput
            :title="$t('subscriptionsOperations.form.situation')"
            v-model="item.status"
            :disabled="
              $user.admin || $user.role.subscriptions_operations_edit_product
                ? false
                : true
            "
            :values="[
              { name: $t('subscriptionsOperations.draft'), value: 0 },
              { name: $t('subscriptionsOperations.sent'), value: 1 },
              { name: $t('subscriptionsOperations.seen'), value: 2 },
              { name: $t('subscriptionsOperations.driven'), value: 3 },
              { name: $t('subscriptionsOperations.canceled'), value: 4 },
            ]"
          />
          <searchGroupInput
            :type="'safe'"
            :values="$database.safes"
            :inputs="[{ 'show': 'name'}]"
          />
          <!--checkInput
            :title="$t('subscriptionsOperations.form.PrintTheInvoice')"
            :value="print"
            v-model="print"
            v-if="$route.name == 'subscriptionsOperationsCreate'"
          /-->

          <dvider
            :title="$t('subscriptionsOperations.form.ProductsInformation')"
            :description="$t('subscriptionsOperations.form.BillProductInformation')"
          />
          <productsTable class="col-12" />
          

          <dvider
            :title="$t('subscriptionsOperations.form.RequestDetails')"
            :description="$t('subscriptionsOperations.form.AdditionalDisplayInformation')"
          />

          <div class="col-12 data-table-header">
            <div class="row">
              <div :class="'col-xl-2'">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('subscriptionsOperations.form.subTotal') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.subtotal + item.discount) }}
                  </div>
                </div>
              </div>

              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('subscriptionsOperations.form.totalTaxes') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.tax) }} 
                  </div>
                </div>
              </div>

              <div :class="item.edit > 0 ? 'col-xl-1' : 'col-xl-2'">
                <div class="row">
                  <div class="col-xl-12 col-6">{{$t('subscriptionsOperations.form.rebate')}}</div>
                  <div class="col-xl-12 col-6">
                    - {{ $moneyFormat(item.discount) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-1">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('subscriptionsOperations.form.Total') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.total) }}
                  </div>
                </div>
              </div>
              <div class="col-xl-3">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('subscriptionsOperations.form.paid') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    <div
                      class="add-input table-add-input"
                      v-if="$route.name == 'subscriptionsOperationsCreate'"
                    >
                      <input
                        type="number"
                        placeholder="0"
                        class="form-control m-w-50"
                        v-model="item.paid"
                        @keyup="changePrice"
                      />
                      <span>{{ $option.currency }}</span>
                    </div>
                    {{
                      $route.name == "subscriptionsOperationsEdit"
                        ? item.paid + " " + $option.currency
                        : ""
                    }}
                  </div>
                </div>
              </div>
              <div class="col-xl-2">
                <div class="row">
                  <div class="col-xl-12 col-6">
                    {{ $t('subscriptionsOperations.form.rest') }}
                  </div>
                  <div class="col-xl-12 col-6">
                    {{ $moneyFormat(item.rest) }} 
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <hr class="mb-5 mt-0" />
          </div>
          <formTextarea
            :title="$t('subscriptionsOperations.form.noteToTheCustomer')"
            v-model="item.notes"
          />
          <formTextarea
            :title="$t('subscriptionsOperations.form.termsOfOffer')"
            v-model="item.terms"
          />

          <hr class="mt-5 mb-5 col-12" />
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'subscriptionsOperationsCreate' && !$parent.stopEdit"
          >
              {{$t('subscriptionsOperations.form.AddABill')}}
          </a>
          <a
            @click="saveItem"
            class="btn btn-block btn-primary"
            v-if="$route.name == 'subscriptionsOperationsEdit' && !$parent.stopEdit"
          >
              {{$t('subscriptionsOperations.form.InvoiceAdjustment')}}
          </a>
          <loading v-if="$parent.stopEdit"/>
        </div>
      </div>
    </div>
    <addTax v-if="addTax == 1"/>
    <addDiscount v-if="addDiscount == 1"/>
  </div>
</template>
<script>
import axios from "axios";

import dvider from "../elements/add/dvider.vue";
import addHeader from "../elements/add/header.vue";

import formInput from "../elements/add/form/formInput.vue";
import connectionInput from "../elements/add/form/connectionInput.vue";
import formTextarea from "../elements/add/form/formTextarea.vue";
import monyInput from "../elements/add/form/monyInput.vue";
import selectInput from "../elements/add/form/selectInput.vue";
import checkInput from "../elements/add/form/checkInput.vue";

import productsTable from "../elements/add/form/subscriptionsOperations/productsTable.vue";

import addTax from "../elements/popupForms/addTax.vue";
import addDiscount from "../elements/popupForms/addDiscount.vue";
import loading from "../elements/add/loading.vue";


import searchGroupInput from "../elements/add/form/searchGroupInput.vue";

import VueCtkDateTimePicker from "vue-ctk-date-time-picker";
import "vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";

export default {
  data() {
    return {
      path: "/subscriptionsOperations",
      addTax: 0,
      addDiscount: 0,
      item: {
        customer_id: 0,
        supplier_id: 0,
        partner_id: 0,
        project_id: 0,
        project_item_id: 0,
        customer: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        supplier: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        partner: {
          name: "",
          mobile: "",
          address1: "",
          balance: 0,
        },
        client_type: 0,
        status: 1,
        date: "",
        expiry_date: "",
        invoice_group: this.$option.invoice_invoice_group_id,
        store_id: "",
        safe_id: "",
        priceList_id: 0,
        quotationItems: [],
        subtotal: 0,
        discount: 0,
        tax: 0,
        delivery: "",
        edit: "",
        total: 0,
        paid: "",
        rest: 0,
        ProductsCount: 0,
        salesPayment: [],
        safe:{},
      },
      errors: {
        name: false,
        store_id: false,
        invoice_group: false,
        safe_id: false,
      },
      itemIndex: null,
      print: this.$route.name == "subscriptionsOperationsCreate" ? true : false,
    };
  },
  mounted() {
    this.$updateDatabase([
      "customers",
      "suppliers",
      "projects",
      "projectItems",
      "productQuantities",
      "productUnits",
      "units",
      "products",
      "priceLists",
      "materials",
      "invoiceGroups",
      "taxes",
      "discounts",
      "stores",
      "safes",
      "productPrices",
    ]);
    if (this.$route.name == "subscriptionsOperationsEdit") {
      this.$parent.stopEdit = true;
      this.getItem();
    } else {
      this.addQuotationItem();
      if (this.$route.params.id) {
        var customer = this.$database.customers.find(
          (el) => el.id == this.$route.params.id
        );
        this.item.customer_id = this.$route.params.id;
        this.item.customer = customer;
      }
      if (this.$database.stores.length >= 0) {
        this.item.store_id = this.$database.stores[0].id;
      }
      if (this.$database.safes.length == 1) {
        this.item.safe_id = this.$database.safes[0].id;
      }
    }
    if (this.$route.name == "subscriptionsOperationsCreate") {
      this.item.date = this.$nowDate()
      this.item.expiry_date = this.$nowDate(5)
    }
    var elm = this;
      window.addEventListener("keydown", function (e) {
        if (e.key == "F1" || e.key == "Meta") {
          e.preventDefault();
          elm.saveItem();
        }
    })
  },
  methods: {
    getItem() {
      axios
        .get(this.$linkGnirator(this.path + "/" + this.$route.params.id))
        .then((response) => {
          this.$parent.stopEdit = false;
          this.item = response.data;
          this.item.quotationItems.forEach((item) => {
            if (item.track_quantity == 1) {
              var productQuantity =
                this.$database.productQuantities.find(
                  (el) =>
                    el.product_id == item.product_id &&
                    el.store_id == this.item.store_id
                );
              if (productQuantity) {
                item.maxQuantity = productQuantity.quantity + item.quantity;
              }
            }

            var product = this.$database.products.find(
              (el) => el.id == item.product_id
            );
            if (product) {
              var productUnits = this.$database.productUnits.filter(
                (el) => el.product_id == product.id
              );
              var units = [];
              var unit = this.$database.units.find(
                (el) => el.id == product.unit_id
              );
              if (unit) {
                var productUnit = {
                  unit: unit,
                  quantity: 1,
                };
                units.push(productUnit);
              }
              productUnits.forEach((productUnit) => {
                var unit = this.$database.units.find(
                  (el) => el.id == productUnit.unit_id
                );
                if (unit) {
                  productUnit.unit = unit;
                  units.push(productUnit);
                }
              });
              item.units = units;
            }
          });

          if (this.item.quotationItems.length == 0) {
            this.addQuotationItem();
          }
        });
    },
    saveItem() {
      var error = 0;
      this.errors = [];
      if (
        this.item.client_type == 0 &&
        (typeof this.item.customer.name === "undefined" ||
          this.item.customer.name == "") &&
        this.$route.name == "subscriptionsOperationsCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.client_type == 1 &&
        (typeof this.item.supplier.name === "undefined" ||
          this.item.supplier.name == "") &&
        this.$route.name == "subscriptionsOperationsCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        this.item.client_type == 2 &&
        (typeof this.item.partner.name === "undefined" ||
          this.item.partner.name == "") &&
        this.$route.name == "subscriptionsOperationsCreate"
      ) {
        error = 1;
        this.errors.name = true;
      }

      if (
        (typeof this.item.store_id === "undefined" ||
          this.item.store_id == "" ||
          this.item.store_id == 0) &&
        this.$route.name == "subscriptionsOperationsCreate"
      ) {
        error = 1;
        this.errors.store_id = true;
      }
      if (
        this.$route.name == "subscriptionsOperationsCreate" &&
        this.item.paid != 0 &&
        this.item.paid != "" &&
        (typeof this.item.safe_id === "undefined" ||
          this.item.safe_id == "" ||
          this.item.safe_id == 0)
      ) {
        error = 1;
        this.errors.safe_id = true;
      }
      if (
        (typeof this.item.invoice_group === "undefined" ||
          this.item.invoice_group == 0 ||
          this.item.invoice_group == "" ||
          this.item.invoice_group == null) &&
        this.$route.name == "subscriptionsOperationsCreate"
      ) {
        error = 1;
        this.errors.invoice_group = true;
      }
      this.item.quotationItems.forEach((quotationItem) => {
        var productUnitQuantity = 1;
        var productUnit = this.$database.productUnits.find(
          (el) =>
            el.product_id == quotationItem.product_id &&
            el.unit_id == quotationItem.unit_id
        );
        if (productUnit) {
          productUnitQuantity = productUnit.quantity;
        }

        if (quotationItem.price == "" || quotationItem.price <= 0) {
          error = 1;
          quotationItem.priceError = true;
        } else {
          quotationItem.priceError = false;
        }
        if (
          quotationItem.quantity == "" ||
          quotationItem.quantity <= 0 ||
          (quotationItem.maxQuantity != "" &&
            quotationItem.quantity * productUnitQuantity >
              quotationItem.maxQuantity)
        ) {
          error = 1;
          quotationItem.quantityError = true;
        } else {
          quotationItem.quantityError = false;
        }

        if (quotationItem.product_name == "") {
          error = 1;
          quotationItem.productNameError = true;
        } else {
          quotationItem.productNameError = false;
        }
      });
      if (error == 0) {
        this.item.date = this.$fixDate(this.item.date);
        this.item.expiry_date = this.$fixDate(this.item.expiry_date);
        this.changePrice();
        if (this.$route.name == "subscriptionsOperationsCreate") {
          this.$parent.aletText = "تم اضافة الفاتورة بنجاح";
          this.$parent.alertType = "success";
          this.$addToLocalDatabase(this.item, this.path.substring(1));
          
          if (this.$option.print_after) {
            var printData = JSON.parse(JSON.stringify(this.item));
            var today = !isNaN(Date.parse(printData.date))
              ? new Date(printData.date)
              : new Date();

            var date = today.getFullYear() + "-" + (today.getMonth() + 1) + "-" + today.getDate();

            var time = today.getHours() + ":" + today.getMinutes();
            var hours = today.getHours();
            var minutes = today.getMinutes();
            var ampm = hours >= 12 ? "pm" : "am";
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? "0" + minutes : minutes;

            var time = hours + ":" + minutes + " " + ampm;

            printData.date = date;
            printData.time = time;

            printData.created_at = new Date().toISOString("ar-EG", {timeZone: "Africa/Cairo"});

            this.$parent.printedInvoice = printData;
          }
          if (this.item.client_type == 0) {
            var customer = this.$database.customers.find(
              (el) => el.id == this.item.customer_id
            );
            if (customer) {
              customer.balance = customer.balance - this.item.rest;
            }
          } else {
            var supplier = this.$database.suppliers.find(
              (el) => el.id == this.item.supplier_id
            );
            if (supplier) {
              supplier.balance = supplier.balance - this.item.rest;
            }
          }
          if (this.item.paid > 0) {
            var safe = this.$database.safes.find(
              (el) => el.id == this.item.safe_id
            );
            safe.balance = safe.balance - 0 + (this.item.paid - 0);
          }
          this.item.quotationItems.forEach((quotationItem) => {
            var productUnitQuantity = 1;
            var productUnit = this.$database.productUnits.find(
              (el) =>
                el.product_id == quotationItem.product_id &&
                el.unit_id == quotationItem.unit_id
            );
            if (productUnit) {
              productUnitQuantity = productUnit.quantity;
            }

            var productQuantity = this.$database.productQuantities.find(
              (el) =>
                el.product_id == quotationItem.product_id &&
                el.store_id == this.item.store_id
            );
            if (productQuantity && productQuantity.track_quantity == 1) {
              productQuantity.quantity =
                productQuantity.quantity -
                quotationItem.quantity * productUnitQuantity;
            }
          });
          localStorage.database = JSON.stringify(this.$database);
          
          this.$router.go(-1);
          this.$parent.stopEdit = false;
        } else if (this.$route.name == "subscriptionsOperationsEdit") {
          this.item._method = "PUT";
          this.$parent.stopEdit = true;
          axios
            .post(this.$linkGnirator(this.path + "/" + this.item.id), this.item)
            .then((response) => {
              this.$parent.stopEdit = false;
              if (response.data == "quantity error") {
                this.$parent.aletText = "الكمية المطلوبة غير موجودة بالمخازن";
                this.$parent.alertType = "danger";
                window.scrollTo({
                  top: 0,
                  behavior: "smooth",
                });
              } else {
                this.$parent.aletText = "تم تعديل الفاتورة بنجاح";
                this.$parent.alertType = "success";
                this.$router.go(-1);
                return response;
              }
              this.$parent.stopEdit = false;
              this.loading = false;
            });
        }
      } else {
        this.$parent.aletText = this.$t('allerts.pleaseMakeSureOfTheInput');
        this.$parent.alertType = "danger";
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    },
    addQuotationItem() {
      this.item.quotationItems.push({
        id: "",
        invoice_id: this.item.id,
        add_product: false,
        product_name: "",
        product_id: 0,
        description: "",
        unit_id: 0,
        quantity: "",
        price: "",
        tax_id: 0,
        tax_rate: 0,
        discount_type: 0,
        discount_value: "",
        discount_id: 0,
        discount_rate: 0,
        subtotal: 0,
        average: 0,
        discount: 0,
        total: 0,
      });
    },
    changePrice() {
      this.item.subtotal = 0;
      this.item.tax = 0;
      this.item.discount = 0;
      this.item.total = 0;
      this.item.rest = 0;
      this.item.ProductsCount = 0;

      this.item.quotationItems.forEach((qitem) => {
        this.item.subtotal += qitem.subtotal;
        this.item.tax += qitem.average;
        this.item.discount += qitem.discount;
        this.item.total += qitem.total;
        this.item.ProductsCount += qitem.quantity - 0;
      });

      this.item.total += this.item.delivery - 0;
      this.item.total += this.item.edit - 0;
      this.item.rest = this.item.total - this.item.paid;
      if (this.item.client_type == 0) {
        this.item.old_balance = this.item.customer
          ? this.item.customer.balance
          : 0;
      } else if (this.item.client_type == 1) {
        this.item.old_balance = this.item.supplier
          ? this.item.supplier.balance
          : 0;
      }
      this.item.new_balance = this.item.old_balance - this.item.total;
      if (this.item.salesPayment && this.item.paid > 0) {
        this.item.salesPayment.new_balance = this.item.new_balance - 0 + (this.item.paid - 0);
      }
    },
  },
  components: {
    dvider,
    addHeader,
    formInput,
    connectionInput,
    formTextarea,
    monyInput,
    selectInput,
    productsTable,
    addTax,
    addDiscount,
    loading,

    searchGroupInput,

    checkInput,

    VueCtkDateTimePicker,
  },
  watch: {
    "$option.invoice_invoice_group_id": function (val) {
      this.item.invoice_group = val;
    }
  },
};
</script>
